import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config';
import { noop } from 'lodash';
import { Approval, Error, Patient, PatientApproval, Payload, PayloadBase } from 'types';

const { get, put } = axios;

const {
  api: { approval, finish, host, patient, terms },
} = config;

const getData = <T>(
  promise: Promise<AxiosResponse<T>>,
  callback: (data: T) => void = noop,
): Promise<T | Error> =>
  promise
    .then(({ data }: AxiosResponse<T>) => {
      callback(data);
      return data;
    })
    .catch(({ response: { data } }: AxiosError<Error>) => {
      throw data;
    });

const prepareRequest = <T>(
  fn: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>,
  resource: string,
  params?: any,
  data?: T,
): Promise<AxiosResponse<T>> =>
  data ? fn(`${host}${resource}`, data, { params }) : fn(`${host}${resource}`, { params });

export const fetchApproval = ({ callback, params }: PayloadBase): Promise<Approval | Error> =>
  getData<Approval>(prepareRequest(get, approval, params), callback);
export const fetchPatient = ({ callback, params }: PayloadBase): Promise<Patient | Error> => {
  return getData<Patient>(prepareRequest(get, patient, params), callback);
};
export const updatePatient = ({ callback, data, params }: Payload<Patient>): Promise<Patient> =>
  getData<Patient>(prepareRequest<Patient>(put, patient, params, data), callback).then(() => ({
    ...params,
    ...data,
  }));
export const fetchPatientApproval = ({ params, callback }: PayloadBase) =>
  getData<PatientApproval[]>(prepareRequest(get, terms, params), callback);
export const updatePatientApproval = ({ callback, data, params }: Payload<PatientApproval[]>) =>
  getData<PatientApproval[]>(prepareRequest(put, approval, params, data), callback).then(
    () => [], // replace current version (outdated) with empty set; in case of access to page again data will fetch
  );
export const finishApproval = ({ callback, params }: PayloadBase) =>
  getData<number>(prepareRequest(get, finish, params), callback);
