import enApprove3 from 'static/pdf/en/zgoda_na_leczenie_beneslider_mikroimplant.pdf';
import enApprove2 from 'static/pdf/en/zgoda_na_leczenie_ortodontyczne.pdf';
import enApprove5 from 'static/pdf/en/zgoda_na_przekazanie_dokumentacji_medycznej.pdf';
import enApprove1 from 'static/pdf/en/zgoda_na_przetwarzanie_danych_osobowych.pdf';
import enApprove4 from 'static/pdf/en/zgoda_na_zabieg_wybielania_zebow.pdf';
import enApprove6 from 'static/pdf/en/zgoda_wywiad_stomatologiczny.pdf';
import { ApprovalPDFProp } from 'types';

const enApprovalsPDF: ApprovalPDFProp = {
  1: { path: enApprove1 },
  2: { path: enApprove2 },
  3: { path: enApprove3 },
  4: { path: enApprove4 },
  5: { path: enApprove5 },
  6: { path: enApprove6 },
};

export default enApprovalsPDF;
