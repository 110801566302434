/* eslint-disable react/jsx-props-no-spreading */
import config, { PageProps } from 'config';
import { find } from 'lodash';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { ApplicationState } from 'types';

const { pages } = config;

const PrivateRoute = ({ component, path, ...rest }: RouteProps) => {
  const step = useSelector(({ ui: { step: selectorStep } }: ApplicationState) => selectorStep);
  const { path: stepPath }: PageProps = find(pages, { step }) || (({} as any) as PageProps);
  const [{ path: startPath }]: PageProps[] = pages;
  const Component: ComponentType<any> | undefined = component;

  return (
    <Route
      {...rest}
      path={path}
      render={({ location, ...props }) =>
        path === stepPath && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: stepPath || startPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
