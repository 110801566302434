import { Icon } from 'antd';
import Button from 'components/Button';
import Container from 'components/Container';
import config from 'config';
import { push } from 'connected-react-router';
import { resetStorage } from 'helpers/storage';
import withTranslation from 'hoc/Translation';
import React, { FC, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ComponentWithTranslation } from 'types';

const {
  pages: [, { path }],
} = config;

const Start: FC<ComponentWithTranslation> = ({
  translations: {
    start: { description, button },
  },
}): ReactElement => {
  const dispatch = useDispatch();
  const pushState = useCallback(() => dispatch(push(path)), [dispatch]);

  const onNext = useCallback(() => {
    resetStorage();
    pushState();
  }, [pushState]);

  return (
    <Container>
      <p>{description}</p>
      <Button onClick={onNext} type="primary" size="large">
        {button}
        <Icon type="right" />
      </Button>
    </Container>
  );
};

export default withTranslation(['start'])<ComponentWithTranslation>(Start);
