import config from 'config';
import { map } from 'lodash';

const {
  localStorage: { approval, patient, step },
} = config;
const { localStorage } = window;

const getItem = (name: string) => localStorage.getItem(name);
const setItem = (name: string, value: string) => localStorage.setItem(name, value);

export const getStorageApproval = (): string | null => getItem(approval);
export const getStoragePatient = (): string | null => getItem(patient);
export const getStorageStep = (): string | null => getItem(step);

export const setStorageApproval = (value: string) => setItem(approval, value);
export const setStoragePatient = (value: string) => setItem(patient, value);
export const setStorageStep = (value: string) => setItem(step, value);
export const resetStorage = (): void[] =>
  map([approval, patient, step], item => localStorage.removeItem(item));
