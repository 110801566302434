export interface PageProps {
  readonly key: string;
  readonly step: number;
  readonly path: string;
}

interface LocalStorageProps {
  step: string;
  patient: string;
  approval: string;
}

interface ApiProps {
  approval: string;
  patient: string;
  terms: string;
  finish: string;
  host: string;
}

interface SessionProps {
  timeout: number;
  timeoutWarning: number;
}

interface ConfigProps {
  environment: string;
  deviceId: number;
  pages: PageProps[];
  localStorage: LocalStorageProps;
  dateFormat: string;
  isDevelopment: boolean;
  api: ApiProps;
  languages: string[];
  session: SessionProps;
}

const defaultEnvironment = 'development';
const environment: string = process.env.REACT_APP_ENVIRONMENT || defaultEnvironment;

const config: ConfigProps = {
  environment,
  deviceId: 1,
  pages: [
    {
      step: 0,
      key: 'fd4a4fe3-f273-4bca-b3c7-b0671d832a23',
      path: '/start',
    },
    {
      step: 1,
      key: '5222b17c-0140-450b-96b4-8978ff95cb1c',
      path: '/verification',
    },
    {
      step: 2,
      key: 'faa13014-6a5a-4c55-805f-12db0856c615',
      path: '/registration',
    },
    {
      step: 3,
      key: 'a7255688-6992-48fa-91b2-1f165a5ae973',
      path: '/approval',
    },
    {
      step: 4,
      key: '73275308-ba1b-4d78-9a40-584aba93a973',
      path: '/end',
    },
  ],
  localStorage: {
    step: 'step',
    patient: 'patientId',
    approval: 'approvalId',
  },
  dateFormat: 'YYYY-MM-DD',
  isDevelopment: environment === defaultEnvironment,
  api: {
    host: process.env.REACT_APP_API_HOST || 'http://localhost:1337/',
    approval: 'approval',
    patient: 'patient',
    terms: 'approval/getForPatient',
    finish: 'approval/finish',
  },
  session: {
    timeout: 1800000, // 30 minutes
    timeoutWarning: 3, // 3 minute
  },
  languages: ['pl', 'en'],
};

export default config;
