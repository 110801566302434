import { Error, Resource, UiState } from 'types';

export const initialState = <T>(name: string): Resource<T> => ({
  isLoading: false,
  didLoad: false,
  data: ({} as any) as T,
  name,
});

export const request = <T>({ name, ...resource }: Resource<T>, state: UiState): UiState => ({
  ...state,
  [name]: { ...resource, isLoading: true, name },
});

export const success = <T>(
  { name, data, ...resource }: Resource<T>,
  state: UiState,
  updateData: T,
  merge = false,
): UiState => ({
  ...state,
  [name]: {
    ...resource,
    data: merge ? { ...data, ...updateData } : updateData,
    didLoad: true,
    isLoading: false,
    name,
  },
});

export const failure = <T>(
  { name, ...resource }: Resource<T>,
  state: UiState,
  error: Error,
): UiState => ({
  ...state,
  error: {
    ...error,
    name,
  },
  [name]: { ...resource, didLoad: false, isLoading: false, name },
});

export const getData = <T>({ data }: Resource<T>): T => data || (({} as any) as T);
export const didLoad = <T>({ didLoad }: Resource<T>): boolean => didLoad;
export const isLoading = <T>({ isLoading }: Resource<T>): boolean => isLoading;
