import styled from '@emotion/styled';
import { colors } from 'global/variables';
import { find, head, isEqual, last, map, slice } from 'lodash';
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

const { secondaryColor, textColor, white } = colors;

export interface SwitchOptionsProps {
  label: string;
  value: string;
}

interface SwitchProps {
  initialValue: string;
  onChange(value: string): void;
  options: SwitchOptionsProps[];
}

interface SwitchElementProps {
  isLeft: boolean;
}

const StyledSwitch = styled.div<SwitchElementProps>(
  {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0.6rem 0',
    borderRadius: '2rem',
    width: '4.5rem',
    backgroundColor: secondaryColor,
    cursor: 'pointer',
    position: 'relative',
    transition: '0.5s ease',
  },
  ({ isLeft }) => ({
    '&:hover': {
      backgroundPosition: 'right center',

      'strong:first-of-type': {
        color: !isLeft && textColor,
      },
      'strong:nth-of-type(2)': {
        color: isLeft && textColor,
      },
    },
    'strong:first-of-type': {
      marginLeft: '0.15rem',
      color: !isLeft && white,
    },
    'strong:nth-of-type(2)': {
      marginRight: '0.15rem',
      color: isLeft && white,
    },
  }),
);

const StyledStrong = styled.strong`
  text-transform: uppercase;
  z-index: 1;
`;

const StyledButton = styled.div<SwitchElementProps>(
  {
    position: 'absolute',
    left: '2.25rem',
    top: '0.25rem',
    width: '2rem',
    height: '2rem',
    borderRadius: '2rem',
    backgroundColor: white,
    transition: 'left .36s cubic-bezier(.78, .14, .15, .86)',
  },
  ({ isLeft }) => ({
    left: isLeft && '0.25rem',
  }),
);

const Switch: FC<SwitchProps> = ({ initialValue, onChange, options }): ReactElement => {
  const [active, setActive] = useState(head(options));
  const onClick = useCallback(() => {
    setActive(stateActive => (isEqual(stateActive, head(options)) ? last(options) : head(options)));
    const { value } = active;
    onChange(value);
  }, [active, setActive, onChange, options]);

  const isLeft = useMemo(() => isEqual(active, head(options)), [active, options]);

  useEffect(() => {
    setActive(
      initialValue
        ? find(options, ({ value }) => value === initialValue) || head(options)
        : head(options),
    );
  }, [initialValue, options]);

  return (
    <StyledSwitch isLeft={isLeft} onClick={onClick}>
      <StyledButton isLeft={isLeft} />
      {map(slice(options, 0, 2), ({ label }) => (
        <StyledStrong key={label}>{label}</StyledStrong>
      ))}
    </StyledSwitch>
  );
};

export default Switch;
