import actions, {
  FETCH_APPROVAL_FAILURE,
  FETCH_APPROVAL_REQUEST,
  FETCH_APPROVAL_SUCCESS,
  FETCH_PATIENT_APPROVAL_FAILURE,
  FETCH_PATIENT_APPROVAL_REQUEST,
  FETCH_PATIENT_APPROVAL_SUCCESS,
  FETCH_PATIENT_FAILURE,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  FINISH_APPROVAL_FAILURE,
  FINISH_APPROVAL_REQUEST,
  FINISH_APPROVAL_SUCCESS,
  getApproval,
  UPDATE_PATIENT_APPROVAL_FAILURE,
  UPDATE_PATIENT_APPROVAL_REQUEST,
  UPDATE_PATIENT_APPROVAL_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
} from 'actions';
import config from 'config';
import { LocationChangeAction, push } from 'connected-react-router';
import { getStorageStep, setStorageStep } from 'helpers/storage';
import { find, isNumber, toString } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchApproval,
  fetchPatient,
  fetchPatientApproval,
  finishApproval,
  updatePatient,
  updatePatientApproval,
} from 'services/uiApi';
import { Approval, Error, Patient, PatientApproval, Payload } from 'types';
import { AsyncActionCreatorBuilder } from 'typesafe-actions';

const {
  fetchApprovalAsync,
  fetchPatientAsync,
  fetchPatientApprovalAsync,
  finishApprovalAsync,
  setStep,
  updatePatientAsync,
  updatePatientAsyncApproval,
} = actions;
const { pages } = config;

function* setStepHandler({
  payload: {
    isFirstRendering,
    location: { pathname },
  },
}: LocationChangeAction) {
  if (isFirstRendering) {
    // check if page exist
    if (find(pages, { path: pathname })) {
      const stepKey = getStorageStep() || '';
      const { step, path } = find(pages, { key: stepKey }) || {};
      if (isNumber(step)) {
        yield put(setStep(step));
        yield put(push(path));
      }
    } else {
      // handle not found - hide stepper by setting null step
      yield put(setStep(undefined));
    }
  } else {
    const { key, step } = find(pages, { path: pathname }) || {};
    if (key) {
      setStorageStep(toString(key));
      yield put(setStep(step));
    }
  }
}

const apiHandlerCreator = <TReq, TSuc, TFail, TSucPay>(
  {
    failure,
    success,
    request,
  }: AsyncActionCreatorBuilder<[TReq, any], [TSuc, [TSucPay, any]], [TFail, any], never>,
  api: (payload: Payload<TSucPay>) => Promise<TSucPay | Error>,
) =>
  function* fetchApprovalHandler({
    payload: { params, ...payload },
  }: ReturnType<typeof request>): Generator {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const { formId } = yield select(getApproval);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const response: [TSucPay, any] = yield call(api, {
        ...payload,
        params: { formId, ...params },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      yield put(success(response));
    } catch (err) {
      yield put(failure(err));
    }
  };

function* sagas() {
  yield takeEvery('@@router/LOCATION_CHANGE', setStepHandler);
  yield takeEvery(
    FETCH_APPROVAL_REQUEST,
    apiHandlerCreator<
      typeof FETCH_APPROVAL_REQUEST,
      typeof FETCH_APPROVAL_SUCCESS,
      typeof FETCH_APPROVAL_FAILURE,
      Approval
    >(fetchApprovalAsync, fetchApproval),
  );
  yield takeEvery(
    FETCH_PATIENT_REQUEST,
    apiHandlerCreator<
      typeof FETCH_PATIENT_REQUEST,
      typeof FETCH_PATIENT_SUCCESS,
      typeof FETCH_PATIENT_FAILURE,
      Patient
    >(fetchPatientAsync, fetchPatient),
  );
  yield takeEvery(
    UPDATE_PATIENT_REQUEST,
    apiHandlerCreator<
      typeof UPDATE_PATIENT_REQUEST,
      typeof UPDATE_PATIENT_SUCCESS,
      typeof UPDATE_PATIENT_FAILURE,
      Patient
    >(updatePatientAsync, updatePatient),
  );
  yield takeEvery(
    FETCH_PATIENT_APPROVAL_REQUEST,
    apiHandlerCreator<
      typeof FETCH_PATIENT_APPROVAL_REQUEST,
      typeof FETCH_PATIENT_APPROVAL_SUCCESS,
      typeof FETCH_PATIENT_APPROVAL_FAILURE,
      PatientApproval[]
    >(fetchPatientApprovalAsync, fetchPatientApproval),
  );
  yield takeEvery(
    UPDATE_PATIENT_APPROVAL_REQUEST,
    apiHandlerCreator<
      typeof UPDATE_PATIENT_APPROVAL_REQUEST,
      typeof UPDATE_PATIENT_APPROVAL_SUCCESS,
      typeof UPDATE_PATIENT_APPROVAL_FAILURE,
      PatientApproval[]
    >(updatePatientAsyncApproval, updatePatientApproval),
  );
  yield takeEvery(
    FINISH_APPROVAL_REQUEST,
    apiHandlerCreator<
      typeof FINISH_APPROVAL_REQUEST,
      typeof FINISH_APPROVAL_SUCCESS,
      typeof FINISH_APPROVAL_FAILURE,
      number
    >(finishApprovalAsync, finishApproval),
  );
}

export default sagas;
