/* eslint-disable react/jsx-props-no-spreading */
import { fetchApproval, fetchPatient, startSession } from 'actions';
import { getStorageApproval, getStoragePatient } from 'helpers/storage';
import moment from 'moment';
import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Approval } from 'types';

const withLocalStorage = <P extends {}>(Component: FunctionComponent<P>) => (
  props: P,
): ReactElement => {
  const dispatch = useDispatch();
  const handleStartSession = useCallback(
    ({ id, date }: Approval) =>
      id &&
      dispatch(
        startSession(
          id,
          moment(date)
            .toDate()
            .getTime(),
        ),
      ),
    [dispatch],
  );
  const handleFetchApproval = useCallback(() => dispatch(fetchApproval(handleStartSession)), [
    dispatch,
    handleStartSession,
  ]);
  const handleFetchPatient = useCallback(id => () => dispatch(fetchPatient(id)), [dispatch]);
  useEffect(() => {
    const storageApproval = getStorageApproval();
    if (storageApproval) {
      handleFetchApproval();
    }
    const storagePatient = getStoragePatient();
    if (storagePatient) {
      handleFetchPatient(storagePatient)();
    }
  }, [handleFetchApproval, handleFetchPatient]);

  return <Component {...props} />;
};

export default withLocalStorage;
