import config from 'config';
import { didLoad, getData, isLoading } from 'helpers/resources';
import {
  ApplicationState,
  Approval,
  Patient,
  PatientApproval,
  PatientApprovalUpdatePayload,
  Session,
  TrustedPerson,
} from 'types';
import { createAction, createAsyncAction, PayloadAction } from 'typesafe-actions';

const { deviceId } = config;

const SET_STEP = 'SET_STEP';
const RESET_UI = 'RESET_UI';
const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const FETCH_APPROVAL_REQUEST = 'FETCH_APPROVAL_REQUEST';
export const FETCH_APPROVAL_SUCCESS = 'FETCH_APPROVAL_SUCCESS';
export const FETCH_APPROVAL_FAILURE = 'FETCH_APPROVAL_FAILURE';
export const FETCH_PATIENT_REQUEST = 'FETCH_PATIENT_REQUEST ';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'FETCH_PATIENT_FAILURE';
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';
export const FETCH_PATIENT_APPROVAL_REQUEST = 'FETCH_PATIENT_APPROVAL_REQUEST';
export const FETCH_PATIENT_APPROVAL_SUCCESS = 'FETCH_PATIENT_APPROVAL_SUCCESS';
export const FETCH_PATIENT_APPROVAL_FAILURE = 'FETCH_PATIENT_APPROVAL_FAILURE';
export const UPDATE_PATIENT_APPROVAL_REQUEST = 'UPDATE_PATIENT_APPROVAL_REQUEST';
export const UPDATE_PATIENT_APPROVAL_SUCCESS = 'UPDATE_PATIENT_APPROVAL_SUCCESS';
export const UPDATE_PATIENT_APPROVAL_FAILURE = 'UPDATE_PATIENT_APPROVAL_FAILURE';
export const FINISH_APPROVAL_REQUEST = 'FINISH_APPROVAL_REQUEST';
export const FINISH_APPROVAL_SUCCESS = 'FINISH_APPROVAL_SUCCESS';
export const FINISH_APPROVAL_FAILURE = 'FINISH_APPROVAL_FAILURE';
export const SET_SESSION = 'SET_SESSION';
export const APPROVAL_RESOURCE = 'approval';
export const PATIENT_APPROVAL_RESOURCE = 'patientApproval';
export const PATIENT_RESOURCE = 'patient';

const setStepAction = createAction(SET_STEP)<number | undefined>();
const resetAction = createAction(RESET_UI)();
const switchLanguageAction = createAction(SWITCH_LANGUAGE)();
const setSessionAction = createAction(SET_SESSION, (session: Session) => session)();

const fetchApprovalAsyncAction = createAsyncAction(
  FETCH_APPROVAL_REQUEST,
  FETCH_APPROVAL_SUCCESS,
  FETCH_APPROVAL_FAILURE,
)<any, Approval, any>();
const fetchPatientAsyncAction = createAsyncAction(
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  FETCH_PATIENT_FAILURE,
)<any, Patient, any>();
const updatePatientAsyncAction = createAsyncAction(
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
)<any, Patient, any>();
const fetchPatientApprovalAsyncAction = createAsyncAction(
  FETCH_PATIENT_APPROVAL_REQUEST,
  FETCH_PATIENT_APPROVAL_SUCCESS,
  FETCH_PATIENT_APPROVAL_FAILURE,
)<any, PatientApproval[], any>();
const updatePatientApprovalAsyncAction = createAsyncAction(
  UPDATE_PATIENT_APPROVAL_REQUEST,
  UPDATE_PATIENT_APPROVAL_SUCCESS,
  UPDATE_PATIENT_APPROVAL_FAILURE,
)<any, PatientApproval[], any>();
const finishApprovalAsyncAction = createAsyncAction(
  FINISH_APPROVAL_REQUEST,
  FINISH_APPROVAL_SUCCESS,
  FINISH_APPROVAL_FAILURE,
)<any, number, any>();

export const fetchApproval = (
  callback: (data: Approval) => void,
): PayloadAction<typeof FETCH_APPROVAL_REQUEST, Approval> =>
  fetchApprovalAsyncAction.request({ callback, params: { deviceId } });
export const fetchPatient = (id: number): PayloadAction<typeof FETCH_PATIENT_REQUEST, number> =>
  fetchPatientAsyncAction.request({ params: { id } });
export const updatePatient = (
  id: number,
  data: Patient | TrustedPerson,
  callback: (data: Patient) => void,
) => updatePatientAsyncAction.request({ params: { id }, data, callback });
export const fetchPatientApproval = (
  id: number,
  callback: (data: PatientApproval[]) => void,
): PayloadAction<typeof FETCH_PATIENT_APPROVAL_REQUEST, PatientApproval[]> =>
  fetchPatientApprovalAsyncAction.request({ params: { patientId: id }, callback });
export const updatePatientApproval = (
  id: number,
  data: PatientApprovalUpdatePayload,
  callback: (data: number) => void,
): PayloadAction<typeof UPDATE_PATIENT_APPROVAL_REQUEST, number> =>
  updatePatientApprovalAsyncAction.request({ params: { patientId: id }, data, callback });
export const finishApproval = (id: number): PayloadAction<typeof FINISH_APPROVAL_REQUEST, number> =>
  finishApprovalAsyncAction.request({ params: { id } });

export const startSession = (
  approvalId: number,
  timestamp: number | undefined = Date.now(),
): PayloadAction<typeof SET_SESSION, Session> =>
  setSessionAction({ isSession: true, timestamp, approvalId });
export const stopSession = (): PayloadAction<typeof SET_SESSION, Session> =>
  setSessionAction({ isSession: false, timestamp: null, approvalId: null });

export const getApproval = ({ ui: { approval } }: ApplicationState): Approval =>
  getData<Approval>(approval);
export const getPatientApproval = ({
  ui: { patientApproval },
}: ApplicationState): PatientApproval[] => getData(patientApproval);
export const didPatientApprovalLoad = ({ ui: { patientApproval } }: ApplicationState): boolean =>
  didLoad(patientApproval);
export const getPatient = ({ ui: { patient } }: ApplicationState): Patient => getData(patient);
export const didApprovalLoad = ({ ui: { approval } }: ApplicationState): boolean =>
  didLoad(approval);
export const isApprovalLoading = ({ ui: { approval } }: ApplicationState): boolean =>
  isLoading(approval);
export const isPatientLoading = ({ ui: { patient } }: ApplicationState): boolean =>
  isLoading(patient);

export default {
  fetchApprovalAsync: fetchApprovalAsyncAction,
  fetchPatientAsync: fetchPatientAsyncAction,
  fetchPatientApprovalAsync: fetchPatientApprovalAsyncAction,
  updatePatientAsync: updatePatientAsyncAction,
  updatePatientAsyncApproval: updatePatientApprovalAsyncAction,
  finishApprovalAsync: finishApprovalAsyncAction,
  reset: resetAction,
  setStep: setStepAction,
  switchLanguage: switchLanguageAction,
  setSession: setSessionAction,
};
