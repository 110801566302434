/* eslint-disable react/jsx-props-no-spreading */

import Modal from 'components/Modal';
import React, { ComponentType, FunctionComponent, ReactElement, useState } from 'react';
import { PortalWithState } from 'react-portal';
import { ModalProps } from 'types';

const withModal = <P extends object>(
  Component: ComponentType<P>,
): FunctionComponent<P & ModalProps> => (props: P): ReactElement => {
  const [text, setText] = useState('');
  const [type, setType] = useState(0);

  const openModal = (newText: string, newType: number) => {
    setText(newText);
    setType(newType);
  };

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ closePortal, openPortal, portal }) => (
        <>
          <Component
            openModal={(newText: string, newType: number) => {
              openModal(newText, newType);
              openPortal();
            }}
            {...props}
          />
          {portal(<Modal closeModal={closePortal} text={text} type={type} />)}
        </>
      )}
    </PortalWithState>
  );
};

export default withModal;
