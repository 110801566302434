import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import actions, { finishApproval, getApproval, getPatient } from 'actions';
import { Avatar, Col, Row, Steps } from 'antd';
import LanguageSwitch from 'components/LanguageSwitch';
import config, { PageProps } from 'config';
import { push } from 'connected-react-router';
import { colors, media } from 'global/variables';
import getMessage, { ORTPAD_1 } from 'helpers/error';
import { resetStorage } from 'helpers/storage';
import withLocalStorage from 'hoc/LocalStorage';
import withModal from 'hoc/Modal';
import withTranslation from 'hoc/Translation';
import { head, isNumber, last, template, trimStart } from 'lodash';
import moment from 'moment';
import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown-now';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'static/logo.png';
import {
  ApplicationState,
  ComponentWithModal,
  ComponentWithTranslation,
  Error as ErrorProp,
  ParentComponentProps,
} from 'types';

const { Step } = Steps;
const { reset } = actions;
const {
  isDevelopment,
  pages,
  session: { timeout, timeoutWarning },
} = config;
const { borderColor, primaryColor, red, secondaryColor, white } = colors;
const {
  xl,
  css: { xs },
} = media;

const blink = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
    color: ${white};
  }

  40%, 43% {
    transform: translate3d(0, -20px, 0);
    color: ${red};
  }

  70% {
    transform: translate3d(0, -10px, 0);
    color: ${red};
  }

  90% {
    transform: translate3d(0,-4px,0);
    color: ${white};
  }
`;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: ${xl}px;
  overflow-x: hidden;
`;

const StyledPageContainer = styled(Row)`
  margin: 2rem 0;
  ${xs} {
    margin: 1rem 0;
  }
`;

const StyledHeader = styled.div({
  background: primaryColor,

  '> div': {
    boxShadow: '0 8px 6px -6px rgba(0,0,0,0.5)',
    height: '5rem',
    margin: '0 0.5rem 0 0.5rem',
    background: `url(${logo}) no-repeat left center`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  '> span': {
    position: 'absolute',
    right: '0',
    top: '0',
  },
});

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid ${borderColor};
  padding: 0.5rem;
`;

const StyledSteps = styled(Steps)({
  justifyContent: 'center',
  '.ant-steps-item-icon': {
    transition: 'background-color 250ms linear',
    borderColor,
  },
});

const StyledStepHorizontal = styled(StyledSteps)({
  '.ant-steps-item': {
    flex: '0 0',
    flexBasis: 'auto',
  },

  [xs]: {
    display: 'none !important',
  },
});

const StyledStepVertical = styled(StyledSteps)({
  display: 'none',

  [xs]: {
    display: 'block',
    marginTop: '2rem',
    '.ant-steps-item-title': {
      display: 'none',
    },
  },
});

interface AppProps extends ParentComponentProps, ComponentWithModal, ComponentWithTranslation {}

type StyledSpanProps = {
  isWarning: boolean;
};

const StyledSpan: FC<StyledSpanProps> = styled.span(
  {
    color: white,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: '0 1rem',
  },
  ({ isWarning }: StyledSpanProps) => ({
    animation: isWarning && `${blink} 1s ease infinite`,
  }),
);

const App: FC<AppProps> = ({
  children,
  openModal,
  translations: {
    errors,
    global: { copyright, version },
    ...translations
  },
}): ReactElement => {
  const step: number | undefined = useSelector(
    ({ ui: { step: selectorStep } }: ApplicationState) => selectorStep,
  );
  const { id } = useSelector((state: ApplicationState) => getApproval(state));
  const { avatar } = useSelector((state: ApplicationState) => getPatient(state));
  const { isSession, timestamp } = useSelector(({ ui: { session } }: ApplicationState) => session);
  const error: ErrorProp = useSelector(
    ({ ui: { error: stateError } }: ApplicationState) => stateError,
  );
  const dispatch = useDispatch();
  const pushes = useMemo(() => pages.map(({ path }: PageProps) => () => dispatch(push(path))), [
    dispatch,
  ]);
  const handleReset = useCallback(() => dispatch(reset()), [dispatch]);
  const handleFinish = useCallback(() => {
    dispatch(finishApproval(id));
  }, [dispatch, id]);
  const onChange = (current: number) => pushes[current]();

  // eslint-disable-next-line react/prop-types
  const renderer = ({ minutes, seconds }: CountdownTimeDelta) => (
    <StyledSpan isWarning={minutes < timeoutWarning}>
      {minutes}:{seconds < 10 && 0}
      {seconds}
    </StyledSpan>
  );
  const onComplete = useCallback(
    (code = ORTPAD_1) => {
      openModal(getMessage(code, errors), 0);
      resetStorage();
      handleFinish();
      handleReset();
      head(pushes)();
    },
    [errors, handleFinish, handleReset, openModal, pushes],
  );

  const onSessionEnd = useCallback(() => onComplete(), [onComplete]);

  useEffect(() => {
    if (error) {
      const { data } = error;
      onComplete(data);
    }
  }, [error]);

  const { step: lastStep }: PageProps = useMemo(() => last(pages) || pages[0], []);
  const isStep = useMemo(() => isNumber(step), [step]);
  const Pages =
    step !== undefined &&
    pages.map(({ step: pageStep, path }: PageProps) => (
      <Step
        key={path}
        disabled={pageStep >= step || step === lastStep}
        title={pageStep === step ? translations[trimStart(path, '/')].title : ''}
      />
    ));

  return (
    <StyledApp>
      <StyledHeader id="applicationHeader">
        <div>
          {isSession && (
            <>
              <Countdown renderer={renderer} date={timestamp + timeout} onComplete={onSessionEnd} />
              {avatar ? (
                <Avatar src={`data:image/png;base64, ${avatar}`} size="large" />
              ) : (
                <Avatar icon="user" style={{ backgroundColor: secondaryColor }} size="large" />
              )}
            </>
          )}
          <LanguageSwitch />
        </div>
      </StyledHeader>
      <StyledPageContainer type="flex" justify="center">
        {isStep && (
          <Col xs={4} sm={20}>
            <StyledStepHorizontal current={step} onChange={onChange}>
              {Pages}
            </StyledStepHorizontal>
            <StyledStepVertical direction="vertical" current={step} onChange={onChange}>
              {Pages}
            </StyledStepVertical>
          </Col>
        )}
        <Col xs={16} sm={20}>
          {children}
        </Col>
      </StyledPageContainer>
      <StyledFooter>
        <span>{template(copyright)({ year: moment().year() })}</span>
        <span>
          {template(version)({
            version: isDevelopment ? process.env.DEV_VERSION : process.env.VERSION,
          })}
        </span>
      </StyledFooter>
    </StyledApp>
  );
};

export default withModal<AppProps>(withTranslation()<AppProps>(withLocalStorage<AppProps>(App)));
