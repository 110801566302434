/* eslint-disable react/jsx-props-no-spreading */
import enError from 'locales/en/error.yaml';
import en from 'locales/en/main.yaml';
import enValidation from 'locales/en/validation.yaml';
import plError from 'locales/pl/error.yaml';
import pl from 'locales/pl/main.yaml';
import plValidation from 'locales/pl/validation.yaml';
import { get, isEmpty, merge, pick } from 'lodash';
import React, { FunctionComponent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { enApprovalsPDF, plApprovalsPDF } from 'static/pdf';
import { ApplicationState } from 'types';

const { files: enFiles, ...enTranslations } = en;
const { files: plFiles, ...plTranslations } = pl;

const translations = {
  en: {
    ...enTranslations,
    errors: enError,
    files: merge(enApprovalsPDF, enFiles),
    validations: enValidation,
  },
  pl: {
    ...plTranslations,
    errors: plError,
    files: merge(plApprovalsPDF, plFiles),
    validations: plValidation,
  },
};

const withTranslation = (sections: string[] = []) => <P extends {}>(
  Component: FunctionComponent<P>,
) => (props: P): ReactElement => {
  const language = useSelector(
    ({ ui: { language: stateLanguage } }: ApplicationState) => stateLanguage,
  );

  return (
    <Component
      translations={
        isEmpty(sections)
          ? get(translations, [language])
          : pick(get(translations, [language]), sections)
      }
      {...props}
    />
  );
};

export default withTranslation;
