import styled from '@emotion/styled';
import { Button, Icon } from 'antd';
import { colors } from 'global/variables';
import withTranslation from 'hoc/Translation';
import React, { FC, ReactElement } from 'react';
import { ComponentWithTranslation } from 'types';

const { borderColor, green, red, primaryColor } = colors;

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 20rem;
  text-align: center;
`;

const StyledRibbon = styled.div`
  width: 100%;
  background-color: ${primaryColor};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.span`
  color: white;
  padding: 0.5rem;
  cursor: pointer;
`;

const StyledCloseIcon = styled(Icon)`
  color: white;
  padding: 0.5rem;
`;

const StyledContent = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${borderColor};
  border-left: 1px solid ${borderColor};
  border-right: 1px solid ${borderColor};
`;

const StyledStatusIcon = styled(Icon)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const StyledDoneStatusIcon = styled(StyledStatusIcon)`
  color: ${green};
`;

const StyledFailStatusIcon = styled(StyledStatusIcon)`
  color: ${red};
`;

const stutusIcons = [
  <StyledDoneStatusIcon key="check-square" type="check-square" />,
  <StyledFailStatusIcon key="warning" type="warning" />,
];

interface ModalProps extends ComponentWithTranslation {
  closeModal(): void;
  type: number;
  text: string;
}

const Modal: FC<ModalProps> = ({
  closeModal,
  translations: {
    global: { confirm, title },
  },
  type,
  text,
}): ReactElement => (
  <StyledModal>
    <StyledContainer>
      <StyledRibbon>
        <StyledTitle>{title}</StyledTitle>
        <StyledCloseIcon type="close" onClick={closeModal} />
      </StyledRibbon>
      <StyledContent>
        {stutusIcons[type]}
        <p>{text}</p>
        <Button type="primary" onClick={closeModal}>
          {confirm}
        </Button>
      </StyledContent>
    </StyledContainer>
  </StyledModal>
);

export default withTranslation(['global'])<ModalProps>(Modal);
