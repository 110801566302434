const mediaQuery = (breakpoint: number): string => `@media screen and (max-width: ${breakpoint}px)`;

const xs = 576;
const md = 768;
const lg = 992;
const xl = 1200;
const xxl = 1600;

export const colors = {
  primaryColor: '#792C7A',
  secondaryColor: '#D2D817',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  green: '#B4D548',
  red: '#D54A48',
  textColor: 'rgba(0, 0, 0, 0.65)',
  white: '#FFFFFF',
};

export const media = {
  xs,
  md,
  lg,
  xl,
  xxl,
  css: {
    xs: mediaQuery(xs),
    md: mediaQuery(md),
    lg: mediaQuery(lg),
    xl: mediaQuery(xl),
    xxl: mediaQuery(xxl),
  },
};
