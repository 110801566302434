import styled from '@emotion/styled';
import { RowProps } from 'antd/lib/row';
import { colors, media } from 'global/variables';
import React, { FC, ReactElement } from 'react';
import borderBottom from 'static/border-bottom.png';
import borderTop from 'static/border-top.png';
import { ParentComponentProps } from 'types';

const { borderColor } = colors;
const {
  css: { xs },
} = media;

const StyledContainer: React.FC<RowProps> = styled.div`
  border: 1px solid ${borderColor};
  padding: 3rem 1rem;
  position: relative;
  margin: 4rem 0;
  text-align: center;

  ${xs} {
    margin: 2rem 0;
  }
`;

const borderStyles = `
  position: absolute;
  left: calc(50% - 2rem);
  width: 3.75rem;
  height: 3.75rem;
`;

const StyledBorderTop = styled.div`
  background: url(${borderTop}) center bottom no-repeat;
  top: -1.9rem;
  ${borderStyles}
`;

const StyledBorderBottom = styled.div`
  background: url(${borderBottom}) center bottom no-repeat;
  bottom: -1.9rem;
  ${borderStyles}
`;

interface ContainerProps extends ParentComponentProps, RowProps {}

const Container: FC<ContainerProps> = ({ id, children }): ReactElement => (
  <StyledContainer id={id}>
    <StyledBorderTop />
    <StyledBorderBottom />
    {children}
  </StyledContainer>
);

export default Container;
