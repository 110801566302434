/* eslint-disable react/jsx-props-no-spreading */

import styled from '@emotion/styled';
import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { FC, ReactElement } from 'react';

const StyledButton = styled(AntButton)`
  height: 4rem;
  padding: 0 2rem;
`;

const Button: FC<ButtonProps> = ({ children, ...props }): ReactElement => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default Button;
