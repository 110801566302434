import styled from '@emotion/styled';
import actions from 'actions';
import Switch from 'components/Switch';
import config from 'config';
import { map } from 'lodash';
import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'types';

const { languages } = config;

const { switchLanguage } = actions;
const StyledLanguageSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 0.5rem;
`;

interface LanguageSwitch {
  initialValue?: string;
}
const LanguageSwitch: FC<LanguageSwitch> = ({ initialValue }): ReactElement => {
  const dispatch = useDispatch();
  const language = useSelector(
    ({ ui: { language: stateLanguage } }: ApplicationState) => stateLanguage,
  );
  const handleSwitchLanguage = useCallback(() => dispatch(switchLanguage()), [dispatch]);
  const onLanguageChange = useCallback(() => handleSwitchLanguage(), [handleSwitchLanguage]);

  const options = useMemo(
    () => map(languages, language => ({ label: language, value: language })),
    [],
  );

  return (
    <StyledLanguageSwitch>
      <Switch
        initialValue={initialValue || language}
        onChange={onLanguageChange}
        options={options}
      />
    </StyledLanguageSwitch>
  );
};

export default LanguageSwitch;
