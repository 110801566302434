import styled from '@emotion/styled';
import { Spin } from 'antd';
import Button from 'components/Button';
import React, { FC, ReactElement } from 'react';

const StyledLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
`;

interface LoaderProps {
  error: object;
  pastDelay: boolean;
  retry(): void;
  timedOut: boolean;
}

const Loader: FC<LoaderProps> = ({ error, pastDelay, retry, timedOut }): ReactElement => {
  if (error) {
    // TO DO: handle error - display modal
    return (
      <div>
        Error! <Button onClick={retry}>Retry</Button>
      </div>
    );
  }
  if (timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <Button onClick={retry}>Retry</Button>
      </div>
    );
  }
  if (pastDelay) {
    // When the loader has taken longer than the delay
    return (
      <StyledLoader>
        <Spin />
      </StyledLoader>
    );
  }
  // When the loader has just started
  return <div />;
};

export default Loader;
