import { css, Global } from '@emotion/core';
import Loader from 'components/Loader';
import PrivateRoute from 'components/PrivateRoute';
import config from 'config';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import Start from 'containers/Start';
import { createBrowserHistory, Location } from 'history';
import React, { FC, ReactElement, useEffect } from 'react';
import { render } from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { animated, useTransition, UseTransitionResult } from 'react-spring';
import createStore from 'store';

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();
const createdStore = createStore(history);
const {
  pages: [
    { path: start },
    { path: verification },
    { path: registration },
    { path: approval },
    { path: end },
  ],
} = config;

const globalStyles = css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Magra', sans-serif;
  }

  #root {
    height: 100%;
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  .datepicker {
    bottom: calc(50% - 150px) !important;
  }
`;

let currentPathname: string = null;
let currentSearch: string = null;

const AppSwitch: FC = (): ReactElement => {
  const location = useLocation();
  const transitions = useTransition(location, ({ pathname }) => pathname, {
    from: { opacity: 0, transform: 'translate(100%, 0)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { display: 'none' },
  });

  // prevent go back on last page
  useEffect(() => {
    history.listen(({ pathname, search }, action: string) => {
      if (action === 'RELPACE') {
        if (pathname !== currentPathname || search !== currentSearch) {
          // Save new location
          currentPathname = pathname;
          currentSearch = search;

          // Clone location object and push it to history
          history.push({
            pathname,
            search,
          });
        }
      }
      if (action === 'POP' && pathname === approval) {
        history.go(1);
      }
    });
  }, []);

  return (
    <>
      {transitions.map(({ item, props, key }: UseTransitionResult<Location, any>) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route path={start} component={Start} />
            <PrivateRoute
              path={verification}
              component={Loadable({
                loader: () => import('containers/Verification'),
                loading: Loader,
              })}
            />
            <PrivateRoute
              path={registration}
              component={Loadable({
                loader: () => import('containers/Registration'),
                loading: Loader,
              })}
            />
            <PrivateRoute
              path={approval}
              component={Loadable({
                loader: () => import('containers/Approval'),
                loading: Loader,
              })}
            />
            <PrivateRoute
              path={end}
              component={Loadable({
                loader: () => import('containers/End'),
                loading: Loader,
              })}
            />
            <PrivateRoute exact path="/" component={Start} />
            <Route
              component={Loadable({
                loader: () => import('containers/NotFound'),
                loading: Loader,
              })}
            />
          </Switch>
        </animated.div>
      ))}
    </>
  );
};

render(
  <Provider store={createdStore}>
    <ConnectedRouter history={history}>
      <Global styles={globalStyles} />
      <App>
        <AppSwitch />
      </App>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.register();
