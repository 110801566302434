import plApprove3 from 'static/pdf/pl/zgoda_na_leczenie_beneslider_mikroimplant.pdf';
import plApprove2 from 'static/pdf/pl/zgoda_na_leczenie_ortodontyczne.pdf';
import plApprove5 from 'static/pdf/pl/zgoda_na_przekazanie_dokumentacji_medycznej.pdf';
import plApprove1 from 'static/pdf/pl/zgoda_na_przetwarzanie_danych_osobowych.pdf';
import plApprove4 from 'static/pdf/pl/zgoda_na_zabieg_wybielania_zebow.pdf';
import plApprove6 from 'static/pdf/pl/zgoda_wywiad_stomatologiczny.pdf';
import { ApprovalPDFProp } from 'types';

const plApprovalsPDF: ApprovalPDFProp = {
  1: { path: plApprove1 },
  2: { path: plApprove2 },
  3: { path: plApprove3 },
  4: { path: plApprove4 },
  5: { path: plApprove5 },
  6: { path: plApprove6 },
};

export default plApprovalsPDF;
