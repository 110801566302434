import actions, { APPROVAL_RESOURCE, PATIENT_APPROVAL_RESOURCE, PATIENT_RESOURCE } from 'actions';
import config from 'config';
import { failure, initialState, request, success } from 'helpers/resources';
import { head, last } from 'lodash';
import { Approval, Error, Patient, PatientApproval, Resource, UiState } from 'types';
import { RootAction } from 'types/types';
import { AsyncActionCreatorBuilder, createReducer, getType, PayloadAction } from 'typesafe-actions';

const {
  setStep,
  reset,
  switchLanguage,
  setSession,
  fetchApprovalAsync,
  fetchPatientApprovalAsync,
  fetchPatientAsync,
  updatePatientAsyncApproval,
  updatePatientAsync,
} = actions;
const { languages } = config;

const initial: UiState = {
  step: 0,
  error: null,
  approval: initialState(APPROVAL_RESOURCE),
  patient: initialState(PATIENT_RESOURCE),
  patientApproval: initialState(PATIENT_APPROVAL_RESOURCE),
  language: head(languages),
  session: {
    isSession: false,
    timestamp: null,
    approvalId: null,
  },
};

const handleAsyncAction = <T>(
  {
    request: asyncRequest,
    success: asyncSuccess,
    failure: asyncFailure,
  }: // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  AsyncActionCreatorBuilder,
  filterState: (state: UiState, payload?: T) => Resource<T>,
  isMerge = false,
): any => ({
  [getType(asyncRequest)]: (state: UiState): UiState => request<T>(filterState(state), state),
  [getType(asyncSuccess)]: (
    state: UiState,
    { payload }: PayloadAction<typeof asyncSuccess, T>,
  ): UiState => success<T>(filterState(state), state, payload, isMerge),
  [getType(asyncFailure)]: (
    state: UiState,
    { payload }: PayloadAction<typeof asyncFailure, Error>,
  ): UiState => failure<T>(filterState(state), state, payload),
});

export default createReducer<UiState, RootAction>(initial, {
  ...handleAsyncAction<Approval>(fetchApprovalAsync, ({ approval }: UiState) => approval),
  ...handleAsyncAction<Patient>(fetchPatientAsync, ({ patient }: UiState) => patient),
  ...handleAsyncAction<Patient>(updatePatientAsync, ({ patient }: UiState) => patient, true),
  ...handleAsyncAction<PatientApproval[]>(
    fetchPatientApprovalAsync,
    ({ patientApproval }: UiState) => patientApproval,
  ),
  ...handleAsyncAction<PatientApproval[]>(
    updatePatientAsyncApproval,
    ({ patientApproval }: UiState) => patientApproval,
  ),
})
  .handleAction([setStep], (state, { payload: step }) => ({ ...state, step }))
  .handleAction([reset], () => ({ ...initial }))
  .handleAction([switchLanguage], ({ language: current, ...state }) => ({
    ...state,
    language: current === head(languages) ? last(languages) : head(languages),
  }))
  .handleAction([setSession], (state, { payload: session }) => ({ ...state, session }));
